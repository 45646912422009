import {css, html, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {msalInstance} from '../auth/authRedirect';
import {GraphAPI} from '../auth/graph';
import {GraphConfig} from '../auth/graphConfig';
import {GraphResponse} from '../interfaces/graphResponse';

@customElement('user-panel')
export class UserPanel extends LitElement {
  static override styles = css``;

  @state() private displayName = '';
  @state() private email = '';

  override connectedCallback(): void {
    super.connectedCallback();
    this.fetchUserData();
  }

  override render() {
    return html`
      <div>
        <p>User: ${this.displayName}</p>
        <p>Email: ${this.email}</p>
        <button @click="${this._logout}">Logout</button>
      </div>
    `;
  }

  private async fetchUserData() {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        // First account is the signed-in user
        const request = {
          account: accounts[0],
          scopes: GraphConfig.graphMeEndpoint.includes('Mail.Read')
            ? ['User.Read', 'Mail.Read']
            : ['User.Read'],
        };

        const tokenResponse = await msalInstance.acquireTokenSilent(request);
        GraphAPI.callMSGraph(GraphConfig.graphMeEndpoint, tokenResponse.accessToken, (response) => {
          this.displayName = (response as GraphResponse).displayName;
          this.email =
            (response as GraphResponse).mail || (response as GraphResponse).userPrincipalName || ''; // Some accounts may not have the mail attribute
        });
      }
    } catch (err) {
      console.error('Failed to fetch user data', err);
    }
  }

  private _logout() {
    msalInstance.logoutRedirect().catch((error) => {
      console.error('Logout failed', error);
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'user-panel': UserPanel;
  }
}
